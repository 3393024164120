import styled, { keyframes } from 'styled-components'
import EyeLoadingScreen from '../../../components/Shared/svg/EyeLoadingScreen'

import { siteBreakPoints, colors } from '../../../consts/styleConsts'
const { small, xSmall, heightSmall } = siteBreakPoints
const { darkGrey1 } = colors.grey

const spinAnimation = keyframes`
    from {transform: translate(-50%, -50%) rotate(0deg)}
    to {transform: translate(-50%,-50%) rotate(360deg)}
`
export const Wrapper = styled.div`
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  transition: opacity 1s 0.6s;
  height: 100%;
  position: fixed;
  width: 100%;
  background: ${darkGrey1};
  z-index: 100;
  @media screen and (min-width: ${small}px) and (max-height: ${heightSmall}px) {
    height: ${heightSmall}px;
  }
`
export const TitleWrapper = styled.div`
  transition: 0.2s ease;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateX(-0.4rem);
  width: 9.09rem;
  height: 1.18rem;
  & svg {
    width: 100%;
  }
  @media screen and (max-width: ${small}px) {
    width: 8.75rem;
  }
}
`
export const EyeImage = styled(EyeLoadingScreen)`
  width: 21.45rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  height: 12.09rem;
  @media screen and (max-width: ${small}px) {
    width: 16.87rem;
    height: 9.5rem;
  }
`
export const LoaderOval = styled.div`
  position: relative;
  border-radius: 50%;
  border: 1px solid #4b7e9b;
  border-bottom: none;
  border-left: none;
  width: 4.45rem;
  height: 4.45rem;
  left: calc(50% - 0.68rem);
  transform: translate(-50%, 0);
  animation: ${spinAnimation} 1s linear infinite;
`
export const FixedBottomWrapper = styled.div`
  height: auto;
  position: fixed;
  bottom: 10%;
  left: 0;
  width: 100%;
  @media screen and (max-width: ${xSmall}px) {
    bottom: 0;
  }
  @media screen and (min-width: ${small}px) and (max-height: ${heightSmall}px) {
    position: absolute;
    height: 1px;
  }
`
