import React from 'react'

import GrainBG from '../../LayoutsRelated/GrainBG/GrainBG'

import TheChangeSVG from '../svg/TheChangeSVG'
import {
  EyeImage,
  TitleWrapper,
  LoaderOval,
  Wrapper,
  FixedBottomWrapper
} from './styled'

const LoadingScreen = ({ isLoading, noGrain }) => {
  return (
    <Wrapper isLoading={isLoading}>
      {noGrain ? null : <GrainBG />}
      <EyeImage />
      <TitleWrapper isLoading={isLoading}>
        <TheChangeSVG />
      </TitleWrapper>
      <FixedBottomWrapper>
        <LoaderOval />
      </FixedBottomWrapper>
    </Wrapper>
  )
}

export default LoadingScreen
